/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //menu
        $('.hamburger').on('click', function(e){
          $('#overlay-menu').fadeIn('fast');
          e.preventDefault();
        });
        $('.close-overlay-menu').on('click', function(e){
          $('#overlay-menu').fadeOut('fast');
          e.preventDefault();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
         $('.fotoslider').slick({
          asNavFor: '.newsslider',
          arrows: false,
          fade: true
        });

         


        $('.newsslider').slick({
          asNavFor: '.fotoslider',
          arrows: false
        });
        $('.news-pager ul li a').on('click', function(e){
          if($(this).hasClass('prev-news-item')){
            $('.newsslider').slick('slickPrev');  
          } else {
            $('.newsslider').slick('slickNext');
          }
          e.preventDefault();
        });


        

        // $('.home-news-slide').css({
        //   height: $('.newssliderwrap').height() + 'px'
        // });

        
        
        $('.go-to-news-slide').on('click', function(e){
          $('.newsslider').slick('slickGoTo', $(this).data('slide'));
          e.preventDefault();
        });

        $('.lessen').slick({
          arrows: false,
          fade: true
        });
        $('.lessonslider nav ul li a').on('click', function(e){
          $('.lessen').slick('slickGoTo', $(this).data('slide'));
          e.preventDefault();
        });

        var lessonsliderheight = 0;
        $('.lessonslider .lesarticle').each(function(index, el) {

            if($(this).height() > lessonsliderheight){
              lessonsliderheight = $(this).height();
            }
        });
        $('.lessonslider .lesarticle article').each(function(index, el) {
          $(this).css({
            height: lessonsliderheight + 'px'
          });
        });
        lessonsliderheight = lessonsliderheight / 4 - 2;
        

        $('.lessonslider nav ul li a').height(lessonsliderheight);
        $('.lessonslider nav ul li a').css('line-height', lessonsliderheight + 'px'); 

        
          
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'lidgeld': {
      init: function() {
        $('#drpgroep').on('change', function(){
          $('#drples').val('');
          if($('#drpgroep').val() !== ''){
            var group  = $('#drpgroep').val();
            $('.lidgeldrij').each(function(index, el) {
              if($(this).data('groep') === group){
                $(this).show();
              } else{
                $(this).hide();
              }
            });
          } else {
            $('.lidgeldrij').each(function(index, el) {
              $(this).show();
            });    
          }
        });
        $('#drples').on('change', function(){
          $('#drpgroep').val('');
          if($('#drples').val() !== ''){
            var les  = $('#drples').val();
            $('.lidgeldrij').each(function(index, el) {
              if($(this).data('les') === les){
                $(this).show();
              } else{
                $(this).hide();
              }
            });
          } else {
            $('.lidgeldrij').each(function(index, el) {
              $(this).show();
            });    
          }
        });
      }
    },
    'lessenrooster': {
      init: function() {
        $('.filterlessenrooster').on('change', function(){
          //alle rijen tonen
          //selectie opvangen
          var groep = $('#drpgroep').val();
          var les = $('#drples').val();
          var shownrows = 0;
          if(groep !== '' && les !== ''){
            //groep en les geselecteerd
            $('.lessenrooster-rij').each(function(index, el) {
              if(String($(this).data('les')) === String(les) && String($(this).data('groep')) === String(groep)){
                $(this).show();
                shownrows++;
              } else {
                $(this).hide();
              }
            });
          } else if(groep !== ''){
            //enkel groep geselecteerd
            $('.lessenrooster-rij').each(function(index, el) {
              if(String($(this).data('groep')) === String(groep)){
                $(this).show();
                shownrows++;
              } else {
                $(this).hide();
              }
            });
          } else if(les !== '') {
            //enkel les geselecteerd
            $('.lessenrooster-rij').each(function(index, el) {
              if(String($(this).data('les')) === String(les)){
                $(this).show();
                shownrows++;
              } else {
                $(this).hide();
              }
            });
          } else {
            //niets geselecteerd, alle stonen
            $('.lessenrooster-rij').each(function(index, el) {
              $(this).show();
              shownrows++;
            });
          }

          //if no results are shown, show message
          if(shownrows > 0){
            $('.noresult').hide();
          } else {
            $('.noresult').show();
          }
          
        });
      }
    },
    'historie': {
      init: function() {
        $('.history-slider').slick({
          arrows: false,
          fade: true,
          asNavFor: '.history-image-slider'
        });
        $('.history-image-slider').slick({
          arrows: false,
          fade: true,
          asNavFor: '.history-slider'
        });
        $('.year-button').on('click', function(e){
          $('.history-slider').slick('slickGoTo', $(this).data('jaar'));
          e.preventDefault();
        });
      }
    },
    'kalender': {
      init: function() {
        $('.dag .wrap').matchHeight();
        $('#changemonth').on('change', function(){
          var selectedmonth = $(this).val();
          $('.maand').each(function(index, el) {
            if(parseInt($(this).data('maand')) === parseInt(selectedmonth)){
              $(this).addClass('active');
              $(this).show();
            } else {
              $(this).removeClass('active');
              $(this).hide();
            }
          }).promise().done( function() {
            $('.dag .wrap').each(function(index, el) {
              $(this).css({
                height: ''
              });
            }).promise().done( function() {
              $('.active .dag .wrap').matchHeight();
            });
          });
        });
      }
    },
  

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
